<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
        elevation="0"
      >
        <v-toolbar-title>
          <v-icon small dark class="mr-1">
            mdi-barcode-scan
          </v-icon>
          Scan Barcode
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="$emit('close', true)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="show" class="pa-4 d-flex fill-width align-center justify-center" style="height:calc(100vh - 56px);">
        <StreamBarcodeReader
          @decode="(a, b, c) => onDecode(a, b, c)"
          @loaded="onLoaded"
          @error="onError"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
export default {
  components: {
    StreamBarcodeReader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    barcode: ''
  }),
  watch: {
    show () {
      this.barcode = ''
    },
    barcode (v) {
      if (v) {
        this.$emit('response', {
          status: 'decoded',
          data: v
        })
      }
    }
  },
  mounted () {
    this.barcode = ''
  },
  methods: {
    onLoaded (result) {
      this.$emit('response', {
        status: 'loaded',
        data: result
      })
    },
    onDecode (a, b, c) {
      this.barcode = a
    },
    onError (result) {
      this.$emit('response', {
        status: 'error',
        data: result
      })
    }
  }
}
</script>
